import React from "react";

export default class DeploymentItems extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            values: {
                A: {},
                B: {},
                C: {}
            }
        };

        this.values = {
            "bike-selection": {
                A: {
                    before: "Select bikes by tapping on the dock number.",
                    after: "Tap on the dock numbers to select your bikes"
                },
                B: {
                    current: "Max {0} bikes"
                },
                C: {
                    before: "Confirm Selection",
                    after: "Confirm {0} Bikes"
                }
            },
            "pass-options": {
                A: {
                    before: "Pass Options & Prices",
                    after: "Pass Options and Pricing"
                },
                B: {
                    current: "Select pass to see details"
                },
                C: {
                    before: "Become a member and ride bikes for as little as 27¢ a day!",
                    after: "Start your membership for little more than a quarter a day!"
                }
            },
            "sms-permission": {
                A: {
                    before: "Can We Notify You Via Text Message?",
                    after: "Yo, can we get your digits?"
                },
                B: {
                    before: "For your convenience, we will send updates regarding your account and other crucial information.",
                    after: "If we send you a text, it'll be useful.  Oh, and we're not going to sell your number."
                },
                C: {
                    before: "Msg and data rates may apply",
                    after: "Messaging and data rates may apply"
                }
            },
            "unlock-bikes": {
                A: {
                    current: "Ready to unlock"
                },
                B: {
                    before: "The docks will be unlocked in numerical order",
                    after: "Docks will be unlocked in numerical order."
                },
                C: {
                    current: "Unlock Bikes"
                }
            },
        };
    }

    componentDidMount() {
        this.updateValuesFromCurrentPage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currentPage !== prevProps.currentPage) {
            this.updateValuesFromCurrentPage();
        }
    }

    updateValuesFromCurrentPage() {
        const textValues = this.values[this.props.currentPage] || null;
        if (textValues) {
            this.setState({
                values: {
                    A: textValues.A || {},
                    B: textValues.B || {},
                    C: textValues.C || {}
                }
            });
        }
    }


    render() {
        return (
            <div className="panel panel-default">
                {Object.entries(this.state.values).map(([key, entries]) => {
                    if (Object.keys(entries).length) {
                        return (
                            <div key={key + "-deploy-change"} className="deployment-change">
                                <div className="col-xs-1 col-xs-offset-1 deployment-label">{key}</div>
                                <div className="col-xs-9 col-xs-offset-1">
                                    {
                                        entries.current ?
                                            <div className="deployment-item">{entries.current}</div> :
                                            (<div>
                                                <div
                                                    className="deployment-item old-deployment-item text-danger">{entries.before}</div>
                                                <div
                                                    className="deployment-item new-deployment-item text-success">{entries.after}</div>
                                            </div>)
                                    }
                                </div>
                            </div>);
                    } else {
                        return ''
                    }
                })}
            </div>
        )
    }
}