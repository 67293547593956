import React from "react";

export default class ManagementFields extends React.PureComponent {
    constructor(props) {
        super(props);

        this.values = {
            'pass-options': {
                A: 'Pass Options & Prices',
                B: 'Select pass to see details',
                C: 'Become a member and ride bikes for as little as 27¢ a day!'
            },
            'sms-permission': {
                A: 'Can We Notify You Via Text Message?',
                B: 'For your convenience, we will send updates regarding your account and other crucial information.',
                C: 'Msg and data rates may apply',
            },
            'unlock-bikes': {
                A: 'Ready to unlock',
                B: 'The docks will be unlocked in numerical order',
                C: 'Unlock Bikes'
            },
            'bike-selection': {
                A: 'Select bikes by tapping on the dock number.',
                B: 'Max {0} bikes',
                C: 'Confirm Selection'
            }
        };

        this.state = {
            A: '',
            B: '',
            C: ''
        };
    }

    textChanged(event, textLetter) {
        this.setState({
            [textLetter]: event.target.value
        });
    }

    componentDidMount() {
        this.updateValuesFromCurrentPage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentPage !== prevProps.currentPage) {
            this.updateValuesFromCurrentPage();
        }
    }

    updateValuesFromCurrentPage() {
        const textValues = this.values[this.props.currentPage] || null;
        if(textValues) {
            this.setState({
                A: textValues.A || '',
                B: textValues.B || '',
                C: textValues.C || ''
            });
        }
    }

    render() {

        if(!this.values[this.props.currentPage]) {
            return (<div/>);
        }

        return (
            <div className="main-space-fields">
                <div className="row field-row">
                    <div className="col-md-6">
                        <div className="input-group">
                            <span className="input-group-addon" id="basic-addonA">A</span>
                            <input type="text" className="form-control" id="pass-options-a"
                                   placeholder="A Text"
                                   onChange={(e) => this.textChanged(e, "A")}
                                   value={this.state.A}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group">
                            <span className="input-group-addon" id="basic-addonB">B</span>
                            <input type="text" className="form-control" id="pass-options-b"
                                   placeholder="B Text"
                                   onChange={(e) => this.textChanged(e, "B")}
                                   value={this.state.B}/>
                        </div>
                    </div>
                </div>
                <div className="row field-row">
                    <div className={this.props.currentPage === 'pass-options' ? "col-md-12" : "col-md-6"}>
                        <div className="input-group">
                            <span className="input-group-addon" id="basic-addonC">C</span>
                            <input type="text" className="form-control" id="pass-options-c"
                                   placeholder="C Text"
                                   onChange={(e) => this.textChanged(e, "C")}
                                   value={this.state.C}/>
                        </div>
                    </div>
                </div>

                <div>
                    <button className="btn btn-lg btn-success"
                            style={{'width': '250px'}}>Save
                    </button>
                </div>
            </div>
        );
    }
}
/*



* */