import React from "react";

export default class DeploymentItems extends React.Component {

    constructor(props) {
        super(props);

        this.values = {};
    }

    render() {
        return (<div className="panel panel-default">
            <div className="deployment-change">
                <div className="col-xs-1 col-xs-offset-1 deployment-label">A</div>
                <div className="col-xs-9 col-xs-offset-1 deployment-images">
                    <div className="col-xs-6 deployment-image grayscale">
                        <img src="/img/kiosk-asset-prototype/bcycle_waterloo.png" alt="Waterloo Bikeshare Logo" />
                    </div>
                    <div className="col-xs-6 deployment-image">
                        <img src="/img/kiosk-asset-prototype/bcycle_rcbs.png" alt="RCBS Logo" />
                    </div>
                </div>
            </div>
            <div className="deployment-change">
                <div className="col-xs-1 col-xs-offset-1 deployment-label">B</div>
                <div className="col-xs-9 col-xs-offset-1">
                    <div className="deployment-item old-deployment-item text-danger">Buy a Pass</div>
                    <div className="deployment-item new-deployment-item text-success">Purchase New Pass</div>
                </div>
            </div>
            <div className="deployment-change">
                <div className="col-xs-1 col-xs-offset-1 deployment-label">C</div>
                <div className="col-xs-9 col-xs-offset-1">
                    <div className="deployment-item old-deployment-item text-danger">I Have a Pass</div>
                    <div className="deployment-item new-deployment-item text-success">Existing Passholder</div>
                </div>
            </div>
            <div className="deployment-change">
                <div className="col-xs-1 col-xs-offset-1 deployment-label">D</div>
                <div className="col-xs-9 col-xs-offset-1">
                    <div className="deployment-item old-deployment-item text-danger">How It Works</div>
                    <div className="deployment-item new-deployment-item text-success">How the Kiosk Works</div>
                </div>
            </div>
        </div>)
    }
}