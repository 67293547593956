import React from "react";

import ManagementFields from "./ManagementFields";
import HomePageManagementFields from "./HomePageManagementFields";

export default class KioskScreenManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 'home-page',
            currentImage: '/img/kiosk-asset-prototype/kiosk-home.png'
        };
    }

    pageSelected(page) {
        this.setState({
            currentPage: page.id,
            currentImage: `/img/kiosk-asset-prototype/${page.image || 'default.png'}`
        });
    }

    render() {
        const screens = {
            "Purchase Flow": [
                {id: 'home-page', text: "Home Page", image: 'kiosk-home.png', isUpdated: true, updateCount: 4},
                {
                    id: 'bike-selection',
                    text: "Bike Selection",
                    image: 'bike-selection.png',
                    isUpdated: true,
                    updateCount: 2
                },
                {
                    id: 'pass-options',
                    text: "Pass Options",
                    image: 'pass-options-screen.png',
                    isUpdated: true,
                    updateCount: 2
                },
                {id: 'pass-details', text: "Pass Details"},
                {id: 'phone-entry', text: "Phone Number Entry"},
                {id: 'user-agreement', text: "User Agreement"},
                {id: 'additional-riders', text: "Additional Riders"},
                {
                    id: 'sms-permission',
                    text: "Opt-In SMS",
                    image: 'sms-permission.png',
                    isUpdated: true,
                    updateCount: 3
                },
                {id: 'purchase-summary', text: "Purchase Summary"},
                {id: 'credit-card-swipe', text: "Credit Card Swipe"},
                {id: 'promo-code', text: "Promo Code Entry"},
                {id: 'zip-code', text: "Zip Code Entry"},
                {
                    id: 'unlock-bikes',
                    text: "Ready to Unlock",
                    image: 'unlock-bikes.png',
                    isUpdated: true,
                    updateCount: 1
                },
                {id: 'unlocking-bikes', text: "Unlocking Bikes"},
                {id: 'unlock-success', text: "Unlock Success"},
            ],
            "Have a Pass Flow": [
                {text: "Existing Passes"},
                {text: "Bike Selection"},
                {text: "Ready to Unlock"},
                {text: "Unlocking Bikes"},
                {text: "Unlock Success"}
            ],
            "How It Works Flow": [
                {text: "How It Works"}
            ],
        };

        return (
            <div className="main-container">
                <div className="column left-column tool-section">
                    <div className="alert section-heading list-group-item-info">Kiosk Screens</div>
                    <div className="scrollable-list">
                        {Object.entries(screens).map(([section, pages]) =>
                            <div key={`${section.toLowerCase().replace(' ', '-')}-list-group`}
                                 className="list-group kiosk-pages-list-group">
                                <div key={`${section.toLowerCase().replace(' ', '-')}-section`}
                                     className="list-group-item list-group-item-heading list-group-item-info">{section}</div>
                                {pages.map(page => <a href="#" key={page.id || page.text}
                                                      onClick={(e) => this.pageSelected(page)}
                                                      className={"list-group-item kiosk-page-list-item" + (page.isUpdated ? ' list-group-item-success' : '') + (page.id === this.state.currentPage ? ' active' : '')}>
                                    {(page.updateCount ? <span className="badge">{page.updateCount}</span> : '')}
                                    {page.text}
                                </a>)}
                            </div>)
                        }
                    </div>
                    <div className="review-updates-button-div" onClick={this.props.showDeploymentTab}>
                        <button className="btn btn-lg btn-success btn-block review-updates-btn">Review for Deployment</button>
                    </div>
                </div>
                <div className="center main-space tool-section">
                    <div className="language-choices">
                        <div className="btn-group btn-group-justified" role="group" aria-label="">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-default active">English</button>
                            </div>
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-default">Español</button>
                            </div>
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-default">Français</button>
                            </div>
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-default">Italiano</button>
                            </div>
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-default">Русский язык</button>
                            </div>
                        </div>
                    </div>
                    {this.state.currentPage === 'home-page' ? <HomePageManagementFields/> :
                        <ManagementFields currentPage={this.state.currentPage}/>}
                    <div className="main-space-image-div">
                        <img className="main-space-image" src={this.state.currentImage}/>
                    </div>
                </div>
            </div>
        )
    }
}