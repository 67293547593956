import React from "react";
import {Typography} from "@material-ui/core";
import LinkGroup from "./LinkGroup";
import deepLinks from "./deep-links";


function DeepLinks() {
    return (
        <div>
            <Typography variant="h4">BCycle Mobile App Links</Typography>
            {deepLinks.map((linkGroup) => <LinkGroup key={linkGroup.header} linkGroup={linkGroup}/>)}
        </div>
    )
}

export default DeepLinks;