import React from "react";

export default class HomePageManagementFields extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            B: 'Buy a Pass',
            C: 'I Have a Pass',
            D: 'How It Works',
        };

        this.fileInput = React.createRef();
    }

    textChanged(event, textLetter) {
        this.setState({
            [textLetter]: event.target.value
        });
    }

    render() {
        return (
            <div className="main-space-fields">
                <div className="row field-row">
                    <div className="col-md-6">
                        <div className="input-group">
                            <span className="input-group-addon" id="basic-addonA">A</span>
                            <input type="file" className="form-control" id="pass-options-a"
                                   ref={this.fileInput}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group">
                            <span className="input-group-addon" id="basic-addonB">B</span>
                            <input type="text" className="form-control" id="pass-options-b"
                                   placeholder="B Text"
                                   onChange={(e) => this.textChanged(e, "B")}
                                   value={this.state.B}/>
                        </div>
                    </div>
                </div>
                <div className="row field-row">
                    <div className="col-md-6">
                        <div className="input-group">
                            <span className="input-group-addon" id="basic-addonC">C</span>
                            <input type="text" className="form-control" id="pass-options-c"
                                   placeholder="C Text"
                                   onChange={(e) => this.textChanged(e, "C")}
                                   value={this.state.C}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-group">
                            <span className="input-group-addon" id="basic-addonD">D</span>
                            <input type="text" className="form-control" id="pass-options-D"
                                   placeholder="D Text"
                                   onChange={(e) => this.textChanged(e, "D")}
                                   value={this.state.D}/>
                        </div>
                    </div>
                </div>

                <div>
                    <button className="btn btn-lg btn-success"
                            style={{'width': '250px'}}>Save
                    </button>
                </div>
            </div>
        );
    }
}
/*



* */