import React from "react";
import Home from "./Home";
import {Route} from "react-router-dom";
import DeepLinks from "../deep-links/DeepLinks";
import KioskAssetPrototype from "../kiosk-asset-prototype/KioskAssetPrototype";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    content: {
        marginTop: '75px'
    }
});

export default function Main() {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <Route exact path='/' component={Home}/>
            <Route path='/deep-links/' component={DeepLinks}/>
            <Route path='/kiosk-asset-prototype/' component={KioskAssetPrototype}/>
        </main>
    )
}