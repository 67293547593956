import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

export default function DeepLink(props) {
    const link = props.link;
    const linkSubtext = link.linkSubtext ?
        link.linkSubtext.join(" - ") :
        "";
    return (
        <ListItem button component="a" href={link.link} dense={true} disableGutters={true} divider={true}>
            <ListItemText primary={link.linkText} secondary={linkSubtext} />
        </ListItem>
    );
}