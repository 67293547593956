import React from 'react';
import './App.css';
import Main from "./main/Main";
import {makeStyles} from "@material-ui/core";
import TopNav from "./main/TopNav";

const useStyles = makeStyles({
    content: {
        flex: 1,
        overflow: 'auto',
        marginTop: '96px'
    }
});

function App() {
    const classes = useStyles();
    return (
        <div className="App">
            <TopNav/>
            <Main className={classes.content}/>
        </div>
    );
}

export default App;
