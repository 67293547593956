import React from "react";

//This causes bootstrap to be included on all pages.  Take it out once this is done.
import 'bootstrap/dist/css/bootstrap.css';

import './css/kiosk-asset-prototype.css';
import KioskScreenManagement from "./KioskScreenManagement";
import KioskScreenDeployment from "./KioskScreenDeployment";

export default class KioskAssetPrototype extends React.Component {

    constructor(props) {
        super(props);

        this.Views = {
            Management: 'management',
            Deployment: 'deployment'
        };

        this.state = {
            currentView: this.Views.Management
        };

        this.showDeploymentTab = this.showDeploymentTab.bind(this);
    }

    changeTab(e, view) {
        if(e) e.preventDefault();

        this.setState({
            currentView: view
        });
    }

    showDeploymentTab() {
        this.setState({
            currentView: this.Views.Deployment
        });
    }

    render() {
        const mainView = this.state.currentView === this.Views.Management ?
            <KioskScreenManagement showDeploymentTab={this.showDeploymentTab}/> :
            <KioskScreenDeployment/>;
        return (
            <div id="kiosk-asset-tool" className="full-container">
                <h1 className="page-title">Kiosk Asset Management</h1>

                <ul className="nav nav-tabs nav-justified">
                    <li className={'nav-item ' + (this.state.currentView === this.Views.Management ? 'active' : '')}>
                        <a className="nav-link" href="#"
                           onClick={(e) => this.changeTab(e, this.Views.Management)}>Management</a>
                    </li>
                    <li className={'nav-item ' + (this.state.currentView === this.Views.Deployment ? 'active' : '')}>
                        <a className="nav-link" href="#"
                           onClick={(e) => this.changeTab(e, this.Views.Deployment)}>Deployment</a>
                    </li>
                </ul>
                {mainView}
            </div>
        )
    }
}