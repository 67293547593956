import React from "react";
import HomePageDeploymentItems from "./HomePageDeploymentItems";
import DeploymentItems from "./DeploymentItems";

export default class KioskScreenDeployment extends React.Component {
    constructor(props) {
        super(props);
        this.screens = [
            {
                id: 'home-page',
                text: "Home Page",
                image: 'kiosk-home.png',
                isUpdated: true,
                updateCount: 4
            },
            {
                id: 'bike-selection',
                text: "Bike Selection",
                image: 'bike-selection.png',
                isUpdated: true,
                updateCount: 2
            },
            {
                id: 'pass-options',
                text: "Pass Options",
                image: 'pass-options-screen.png',
                isUpdated: true,
                updateCount: 2
            },
            {
                id: 'sms-permission',
                text: "Opt-In SMS",
                image: 'sms-permission.png',
                isUpdated: true,
                updateCount: 3
            },
            {
                id: 'unlock-bikes',
                text: "Ready to Unlock",
                image: 'unlock-bikes.png',
                isUpdated: true,
                updateCount: 1
            }
        ];

        const defaultScreen = this.screens[0];

        this.state = {
            currentPage: defaultScreen.id,
            currentImage: `/img/kiosk-asset-prototype/${defaultScreen.image || 'default.png'}`
        };
    }

    screenSelected(screen) {
        this.setState({
            currentPage: screen.id,
            currentImage: `/img/kiosk-asset-prototype/${screen.image || 'default.png'}`
        })
    }

    showDeployMessage() {
        alert("This would send the changes for the given program's kiosks to the Portal.")
    }

    render() {
        return (
            <div className="deployment-container">
                <div className="main-container">
                    <div className="column left-column tool-section">
                        <div className="alert section-heading list-group-item-success">Staged Updates</div>
                        <div className="scrollable-list">
                            <div className="list-group kiosk-pages-staged-list-group">
                                {this.screens.map(screen => <a href="#" key={screen.id || screen.text}
                                                               onClick={(e) => this.screenSelected(screen)}
                                                               className={"list-group-item kiosk-page-list-item" + (screen.id === this.state.currentPage ? ' active' : '')}>
                                    {screen.text}
                                    <span className="badge">{screen.updateCount}</span>
                                </a>)}
                            </div>
                        </div>
                    </div>
                    <div className="center main-space tool-section">
                        {this.state.currentPage === 'home-page' ? <HomePageDeploymentItems/> :
                            <DeploymentItems currentPage={this.state.currentPage}/>}
                    </div>
                </div>

                <div className="deploy-button-div">
                    <button className="btn btn-lg btn-success deploy-button" onClick={this.showDeployMessage}>Deploy
                    </button>
                </div>
            </div>

        )
    }
}