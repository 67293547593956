import React, {Component} from "react";
import { Link } from 'react-router-dom'

class Home extends Component {
    render() {
        return (
            <div>
                <h1>HOME</h1>

                <Link to="/deep-links">Deep Links</Link>
                <br />
                <Link to="/kiosk-asset-prototype">Kiosk Asset Manager Prototype</Link>
            </div>
        )
    }
}

export default Home;