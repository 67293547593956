import React from "react";
import DeepLink from "./DeepLink";
import {Typography} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import List from "@material-ui/core/List";

export default function LinkGroup(props) {

    return (
        <div>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6">{props.linkGroup.header}</Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                    <List component="nav" dense={true}>
                        {props.linkGroup.links.map(link => <DeepLink key={link.link} link={link}/>)}
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}